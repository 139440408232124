import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import ChannelService from '@common-src/service/channel';

export enum ChannelTypeEnum {
    WECHAT_SERVICE= 'WE_CHAT_PUBLIC_ACCOUNT', // 微信服务号
    MESSAGE_CENTER = 'MESSAGE_CENTER', // 消息中心
    LARGE_SCREEN = 'LARGE_SCREEN', // 大屏
    DING_ROBOT = 'DING_TALK_ROBOT', // 钉钉机器人
    FEISHU_ROBOT = 'FEI_SHU_ROBOT', // 飞书机器人
    ALIYUN_SMS = 'ALIYUN_SMS', // 阿里云短信
    EMAIL = 'EMAIL', // 邮件
    FEI_SHU_APPLICATION_ROBOT = 'FEI_SHU_APPLICATION_ROBOT', // 飞书应用机器人
    CORP_WE_CHAT_APPLICATION = 'CORP_WE_CHAT_APPLICATION', // 企业微信应用
    CORP_WE_CHAT_ROBOT = 'CORP_WE_CHAT_ROBOT' // 企业微信机器人
}

// 渠道类型
export const ChannelTypeList = [
    {
        name: '微信服务号',
        value: ChannelTypeEnum.WECHAT_SERVICE
    }, {
        name: '消息中心',
        value: ChannelTypeEnum.MESSAGE_CENTER
    }, {
        name: '大屏',
        value: ChannelTypeEnum.LARGE_SCREEN
    }, {
        name: '钉钉机器人',
        value: ChannelTypeEnum.DING_ROBOT
    }, {
        name: '飞书机器人',
        value: ChannelTypeEnum.FEISHU_ROBOT
    }, {
        name: '阿里云短信',
        value: ChannelTypeEnum.ALIYUN_SMS
    }, {
        name: '邮件',
        value: ChannelTypeEnum.EMAIL
    }, {
        name: '飞书应用机器人',
        value: ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT
    }, {
        name: '企业微信应用',
        value: ChannelTypeEnum.CORP_WE_CHAT_APPLICATION
    }, {
        name: '企业微信机器人',
        value: ChannelTypeEnum.CORP_WE_CHAT_ROBOT
    }
];
//  处理显示关联
export const InvisibleFunc = (...ChannelList) => (model:any) => (
    !_.some(ChannelList, item => model.channelType === item)
);

// 数据脱敏 保留前后各四个字符，其余使用*号填充
export function maskText(inputText) {
    if (!inputText) {
        return;
    }
    if (inputText.length <= 8) {
        return inputText;
    }
    const startText = inputText.slice(0, 4);
    const endText = inputText.slice(-4);
    const maskedText = startText + '*'.repeat(inputText.length - 8) + endText;
    return maskedText;
}

export class ChannelEntityModel extends BaseEntityModel {
    static baseUrl = `${MESSAGE_BASE_REQUEST_PATH}/channel`;

    channelConfig:any={};
    @FormControl({
        label: '渠道名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    channelName: string = undefined;

    @FormControl({
        label: '渠道类型',
        type: FormControlType.SELECT,
        optionsPromise: ChannelService.channelTypeList,
        required: true,
        cascad: true,
        readonly: true
    } as FormControlOptionModel)
    channelType: ChannelTypeEnum = undefined;

    @FormControl({
        label: '企业ID',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.CORP_WE_CHAT_APPLICATION)
    } as FormControlTextModel)
    corpId: string = undefined;

    @FormControl({
        label: '应用Secret',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.CORP_WE_CHAT_APPLICATION)
    } as FormControlTextModel)
    corpSecret: string = undefined;

    @FormControl({
        label: '应用AgentId',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.CORP_WE_CHAT_APPLICATION)
    } as FormControlTextModel)
    agentId: string = undefined;

    @FormControl({
        label: 'AppId',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE, ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT)
    } as FormControlTextModel)
    appId: string = undefined;

    @FormControl({
        label: 'AppSecret',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT)
    } as FormControlTextModel)
    appSecret: string = undefined;

    @FormControl({
        label: '公众号Secret',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    secret: string = undefined;

    @FormControl({
        label: '小程序AppId',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    miniProgramAppId: string = undefined;

    @FormControl({
        label: '数字签名',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.DING_ROBOT, ChannelTypeEnum.FEISHU_ROBOT)
    } as FormControlTextModel)
    secretKey: string = undefined;

    @FormControl({
        label: 'webhook地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.DING_ROBOT, ChannelTypeEnum.FEISHU_ROBOT, ChannelTypeEnum.CORP_WE_CHAT_ROBOT)
    } as FormControlTextModel)
    webhook: string = undefined;

    @FormControl({
        label: 'AccessKey ID',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    accessKeyId: string = undefined;
    originAccessKeyId: string = undefined;

    @FormControl({
        label: 'AccessKey Secret',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    accessKeySecret: string = undefined;
    originAccessKeySecret: string = undefined;

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '渠道名称',
                dataIndex: 'channelName',
                scopedSlots: { customRender: 'name' },
                width: 400
            },
            {
                title: '渠道类型',
                dataIndex: 'channelType',
                customRender: (_text, record) => {
                    return _.get(_.find(ChannelTypeList, item => record.channelType === item.value), 'name');
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 300
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        this.appId = this.channelConfig?.appId;
        this.appSecret = this.channelConfig?.appSecret;
        this.corpId = this.channelConfig?.corpId;
        this.agentId = this.channelConfig?.agentId;
        this.miniProgramAppId = this.channelConfig?.miniProgram?.appId;
        if (this.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION) {
            this.corpSecret = this.channelConfig?.secret;
        } else {
            this.secret = this.channelConfig?.secret;
        }
        // this.secret = this.channelConfig?.secret;
        // this.corpSecret = this.channelConfig?.secret;
        this.secretKey = this.channelConfig?.secretKey;
        this.webhook = this.channelConfig?.webhook;
        this.originAccessKeyId = this.channelConfig?.accessKeyId;
        this.accessKeyId = maskText(this.channelConfig?.accessKeyId);
        this.originAccessKeySecret = this.channelConfig?.accessKeySecret;
        this.accessKeySecret = maskText(this.channelConfig?.accessKeySecret);
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.channelConfig = {
            appId: data.appId,
            appSecret: data.appSecret,
            miniProgram: {
                appId: data.miniProgramAppId
            },
            secretKey: data.secretKey,
            secret: data.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION ? data.corpSecret : data.secret,
            webhook: data.webhook,
            corpId: data.corpId,
            agentId: data.agentId,
            accessKeyId: data.accessKeyId?.includes('*') ? data.originAccessKeyId : data.accessKeyId,
            accessKeySecret: data.accessKeySecret?.includes('*') ? data.originAccessKeySecret : data.accessKeySecret
        };
        delete data.corpSecret;
        delete data.corpId;
        delete data.agentId;
        delete data.secret;
        delete data.appId;
        delete data.appSecret;
        delete data.webhook;
        delete data.secretKey;
        delete data.secret;
        delete data.accessKeyId;
        delete data.originAccessKeyId;
        delete data.accessKeySecret;
        delete data.originAccessKeySecret;
        return data;
    }
}

export class ChannelQueryModel extends QueryPageModel {
    @QueryControl({
        label: '渠道类型',
        type: QueryControlType.SELECT,
        optionsPromise: ChannelService.channelTypeList,
        hasAllOption: true,
        span: 6
    })
    channelType: string = undefined;

    @QueryControl({
        label: '渠道名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    channelName: string = undefined;

    toService() {
        // return { params: this.channelName };
        return super.getParams();
    }
}
